// Global strings for the site
export const DEALERSHIP_NAME = "Don Frank Auto Sales"
export const LOGO_PATH = "/images/logo.png"
export const FAVICON_PATH = "/images/favicon.png"
export const TAGLINE = "Great Vehicles at Great Prices!"
export const ADDRESS = "1350 S. Lakeshore Way   Lake Alfred  FL 33850 "
export const PHONE_NUMBER = "863-835-9198"
export const EMAIL = "donfrankauto@yahoo.com"
export const COLOR_SCHEME = "blue" // Options: 'blue', 'green', 'red', 'purple', 'orange'

// Hours of operation as shown in footer
export const BUSINESS_HOURS = [
  { day: "M-Sat", hours: "9AM-5PM" },
  { day: "Sun ", hours: "10AM-5PM" }
]

// Layout Configuration
export const WANTS_TOP_BAR = true // Toggle entire top bar in layout
export const WANTS_FOOTER = true // Toggle entire footer in layout

// Footer Social Links and Visibility
export const WANTS_FOOTER_SOCIAL = true
export const WANTS_FACEBOOK = true
export const WANTS_INSTAGRAM = true
export const WANTS_TWITTER = true

// Vehicle Detail Page Configurations
export const HAS_CARFAX = true // Toggle Carfax report on vehicle details page
export const HAS_VEHICLE_PAYMENTS = true // Toggle monthly payment display and related functionality

// Vehicle Card Configuration
export const WANTS_CARD_FOOTER = true // Toggle vehicle card footer
export const WANTS_CARD_TOP_LABELS = true // Toggle vehicle card top labels (low miles and low price)
export const WANTS_FAVORITES = true // Toggle favorites icon on vehicle card
export const WANTS_SOCIAL_PROOF = false // Toggle social proof (page views and favorite count)

// Financing Page Configuration
export const WANTS_FINANCING = true // Toggle all things related to financing
export const WANTS_FINANCING_SECTION = true // Toggle visibility of the financing section
export const FINANCING_TITLE = "Get Financing"
export const FINANCING_SUBTITLE = "Use the form below to contact us."
export const FIN_CONTACT_TITLE = "Pre-Financing Form"

// Contact Us Page strings
export const CONTACT_FORM_TITLE = "Contact Us"
export const CONTACT_FORM_SUBTITLE = "Use the form below to contact us."
export const CON_CONTACT_TITLE = "Contact Form"

// Inventory Page Configuration
export const WANTS_INVENTORY_SCROLL = true
export const INVENTORY_SCROLL_NUMBER = "10"
export const WANTS_BROWSE_BY_TYPE = true
export const WANTS_SPECIALTY_TYPES = true

// Additional Features
export const WANTS_TRADE_SECTION = true
export const WANTS_SERVICE_SECTION = true
export const WANTS_TESTIMONIAL_SCROLL = true

// Google Maps Configuration
export const MAP_URL = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.323545130575!2d-81.73467632358539!3d28.075672175973445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd6d1ee078d6f5:0x52c4d75280eca564!2sDON FRANK AUTO SALES LLC!5e0!3m2!1sen!2sus!4v1708389783954!5m2!1sen!2sus" // Embed URL from Google Maps
export const MAP_LINK_URL = "https://www.google.com/maps/place/DON+FRANK+AUTO+SALES+LLC/@28.0756722,-81.7346763,17z/data=!3m1!4b1!4m6!3m5!1s0x88dd6d1ee078d6f5:0x52c4d75280eca564!8m2!3d28.0756722!4d-81.7321014!16s/g/1tdz0s20?entry=ttu" // Google Maps directions link

// Testimonials (Dummy Data)
export const TESTIMONIALS = [
  {
    name: "John Darnold",
    title: "Satisfied Customer",
    testimonial:
      "The dealership was very helpful, and the staff were friendly and knowledgeable. I got a great deal on my new car, and the process was seamless and quick.",
    rating: 5,
  },
  {
    name: "Jane Smith",
    title: "Happy Buyer",
    testimonial:
      "The customer service here is excellent. They helped me find the perfect car for my needs and budget. Their transparency and honesty were much appreciated.",
    rating: 4,
  },
  {
    name: "Alice Johnson",
    title: "Long-time Customer",
    testimonial:
      "I have been coming to this dealership for years, and they never disappoint. They have a wide variety of cars and are always upfront about costs and features.",
    rating: 5,
  },
  {
    name: "Bob Williams",
    title: "First-time Buyer",
    testimonial:
      "As a first-time car buyer, I appreciated the patience and guidance the dealership provided. They made sure I understood everything and didn’t pressure me.",
    rating: 4,
  },
  {
    name: "Charlie Brown",
    title: "Returning Customer",
    testimonial:
      "This is the third car I’ve bought from this dealership, and every experience has been great. The staff is friendly, and their after-sales service is superb.",
    rating: 5,
  },
  {
    name: "Donna Davis",
    title: "Satisfied Customer",
    testimonial:
      "They made the process of trading in my old car for a new one so easy. They gave me a fair price and handled all the paperwork efficiently.",
    rating: 5,
  },
  {
    name: "Edward Johnson",
    title: "Happy Buyer",
    testimonial:
      "The dealership was able to answer all my questions and provide me with all the information I needed. Their level of service and commitment is commendable.",
    rating: 4,
  },
  {
    name: "Fiona White",
    title: "First-time Buyer",
    testimonial:
      "Their selection of cars is excellent, and the staff was very patient and knowledgeable. I never felt rushed or pushed into making a decision.",
    rating: 4,
  },
  {
    name: "George Thompson",
    title: "Long-time Customer",
    testimonial:
      "This dealership always provides top-notch service. Their staff is efficient and friendly, and they have always been able to meet my needs and exceed my expectations.",
    rating: 5,
  },
  {
    name: "Helen Martin",
    title: "Returning Customer",
    testimonial:
      "The finance team at this dealership is incredible. They helped me secure a fantastic financing deal and made sure I understood all the terms and conditions.",
    rating: 5,
  },
]

// Vehicle Card Related Strings
export const VEHICLE_CARD_LOW_PRICE = true
export const VEHICLE_CARD_LOW_MILES = true
export const VEHICLE_CARD_INSPECTED_TEXT = "Dealer Inspected"
export const VEHICLE_CARD_PRIMARY_FOOTER_TEXT = "Local Delivery"
export const VEHICLE_CARD_SECONDARY_FOOTER_TEXT = "Get it Tomorrow"

export const DISCLAIMER = `The estimated monthly payments and estimated down
payments listed on this inventory page are based on a 720 credit
score and a 72 month term with $100,000 annual gross income.
Actual terms may vary based on individual creditworthiness, loan
amount, and length of term. Prices listed may exclude additional
fees and taxes. Please contact us for more information on
financing options and terms.`


